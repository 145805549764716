.container {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  position: relative;
  width: 150px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 7px;
}

.slider__track {
  background-color: #e5e7eb;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #1c64f2;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #1f2937;
  /* margin-top: 20px; */
  bottom: 100%; /* This places the bottom of the element at the top of the parent */
  transform: translate(
    -50%,
    -50%
  ); /* This centers it horizontally and moves it up by its own height */
  white-space: nowrap; /* Ensure the text doesn't wrap */
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 150px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
